/** @format */

import React, { useState } from "react";

const Section = () => {
  const [show, setShow] = useState("");

  const handleClick = () => {
    const textToCopy = "rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1";
    navigator.clipboard.writeText(textToCopy).then(() => {
      setShow("Copied to clipboard");
    });
  };
  return (
    <div className='relative bg-black bg-cover bg-center bg-no-repeat font-[sans-serif] '>
      <div className='bg-black/50'>
        <div className='max-w-5xl  mx-auto text-center text-white py-16 px-6 '>
          <div className='flex  justify-center gap-6 items-center flex-wrap my-2 '>
            <img src='p2.png' className='w-2/6 mt-2 object-contain' alt='' />
            <img src='p3.png' className='w-2/6 mt-2 object-contain' alt='' />
            <img
              src='solscan.svg'
              className='w-1/6 mt-2 object-contain'
              alt=''
            />
            {/* <img
              src='dextools-logo-4EDDD2DF98-seeklogo.com_.png'
              className='w-1/6 mt-2 object-contain'
              alt=''
            /> */}
          </div>
          <h2 className='text-4xl font-extrabold mb-4'>
            Swim2049 Smart Contract
          </h2>

          <div className='mt-10'>
            <input
              type='text'
              value={"rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1"}
              className='w-full sm:w-96 bg-gray-50 py-3.5 px-4 text-[#333] text-sm focus:outline-none rounded-tr text-start'
            />
            <button
              onClick={handleClick}
              className='max-sm:mt-8  bg-[#007bff] hover:bg-[#007bff9a] text-white text-base font-semibold py-3.5 px-6 rounded-tr hover:shadow-md hover:transition-transform transition-transform hover:scale-105 focus:outline-none'>
              Copy
            </button>
          </div>
          <p className='text-white/90'> {show}</p>
        </div>
      </div>
    </div>
  );
};

export default Section;
