/** @format */

import React from "react";

const Section1 = () => {
  return (
    <section className='overflow-hidden bg-[url(../public/Cloud03.jpg)] bg-cover bg-top bg-no-repeat'>
      <div className='bg-black/75 p-8 md:p-12 lg:px-16 lg:py-24'>
        <div className='text-center ltr:sm:text-left rtl:sm:text-right'>
          <h2 className='text-2xl font-bold text-white sm:text-3xl md:text-5xl'>
            <small>Habibi, Come to Dubai!</small> <br />{" "}
            <strong className=' font-title mt-3'>
              We have replaced Camels with Whales
            </strong>
          </h2>
          <p className='  text-white/90 md:mt-6 md:block md:text-base md:leading-relaxed text-center m-auto'>
            Sounds Unreal? It was till Dubai got hit with massive flood and
            unpredictable rain spells.
          </p>
          <p className=' max-w-5xl text-white/90 md:mt-6 md:block md:text-base md:leading-relaxed text-justify m-auto'>
            <strong>
              Hey Crypto Folks! We all were gearing up for the hottest Crypto
              events this year; Token 2049 in Dubai.
            </strong>
            <br />
            <br />
            <strong className='text-2xl'>But Guess what?</strong>
            <br />
            <br />
            <strong>Habibi Said ‘No’ to Token2049.</strong>
            <br />
            <br />
            Flights are delayed, Roads turned into canals and canals into
            rivers. And Lamborghini? No way! You will find discounted pool toys,
            though!
            <br />
            <br />
            Oh, and those pool parties we were anticipating? Yeah, but the
            mother earth took it away and we are left with Crypto- flashflood.
            Seems like, Whales are going to join Token2049 now.
            <br />
            <br />
            While, you must be mourning over ‘Habibi comes to Dubai’ promises, I
            am finding a way to swim through the canals to build Swim2049! The
            biggest whales in the canals might be missing on the opportunities
            due to flooding, but fear not! There is a new opportunity oozing
            from the flooded hotel rooms
            <br />
            <br />
            <strong>
              We are Introducing Swim2049, the hottest meme token hanging out
              with whales!
            </strong>
            <br />
            <br />
            <strong className='text-2xl'>Here's a deal, Habibi!</strong>
            <br />
            <br />
            Every purchase anyone is going to make would be contributing to
            raise funds for our crypto buddies, who could not make it to the
            event. This coin going to be the talk of the new whales town since
            it got a heart!
            <br />
            <br />
            Let’s brace yourself to share this heartfelt gesture with everyone
            and who knows? We might be raising new investment opportunities for
            our fellow crypto-whales.
            <br />
            <br />
            What are you waiting for? Grab your swim suits and dive into the
            canal of opportunities and genuine vibes.
          </p>
          <p className='  text-white/90 md:mt-6 md:block md:text-xl md:leading-relaxed text-center m-auto  font-bold'>
            And hey? Don’t forget to take Habibi along!
          </p>
          <div className='mt-4 sm:mt-8'></div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
