/** @format */

import React from "react";

const Gallery = () => {
  return (
    <div className=' mx-auto px-5 py-2 lg:px-24 lg:py-12'>
      <div className='-m-1 flex flex-wrap md:-m-2'>
        <div className='flex w-1/4 flex-wrap'>
          <div className='w-full p-1 md:p-2'>
            <img
              alt='gallery'
              className='block h-full w-full rounded-lg object-cover object-center'
              src='/1.jpeg'
            />
          </div>
        </div>
        <div className='flex w-1/4 flex-wrap'>
          <div className='w-full p-1 md:p-2'>
            <img
              alt='gallery'
              className='block h-full w-full rounded-lg object-cover object-center'
              src='/2.jpeg'
            />
          </div>
        </div>
        <div className='flex w-1/4 flex-wrap'>
          <div className='w-full p-1 md:p-2'>
            <img
              alt='gallery'
              className='block h-full w-full rounded-lg object-cover object-center'
              src='/3.jpeg'
            />
          </div>
        </div>
        <div className='flex w-1/4 flex-wrap'>
          <div className='w-full p-1 md:p-2'>
            <img
              alt='gallery'
              className='block h-full w-full rounded-lg object-cover object-center'
              src='/4.jpeg'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
