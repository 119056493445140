/** @format */

import "./App.scss";
import React from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Hero from "./component/Hero";
import Section1 from "./component/Section1";
import Section from "./component/Section";
import Gallery from "./component/Gallery";
import Cta from "./component/Cta";

function App() {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Section />
      <Section1 />
      <Gallery />
      <Cta />
      <Footer />
    </React.Fragment>
  );
}

export default App;
