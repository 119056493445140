/** @format */

import React from "react";

const Hero = () => {
  return (
    <div className=' relative bg-[url(../public/cover.png)] bg-contain bg-center bg-no-repeat'>
      <div className='absolute inset-0 bg-black/75 sm:bg-transparent sm:from-black/95 sm:to-black/25  sm:bg-gradient-to-b' />
      <div className='relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-[73vh] lg:items-center lg:px-8'>
        <div className=' grid grid-cols-1'>
          <div className='max-w-xl text-center sm:text-left m-auto'>
            <div className='mt-8 flex flex-wrap gap-4 text-center'>
              <a
                href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=rX3wHE&outputCurrency=rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1&fixed=out'
                target='_blank'
                rel='noReferrer'
                className='block w-full rounded  px-12 py-3 text-sm font-medium text-white shadow  focus:outline-none focus:ring  sm:w-auto   border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]'>
                Buy Swim2049
              </a>
              <a
                href='https://www.dextools.io/app/en/solana/pair-explorer/FzGYbBXrCkKqyhU8XKsAStHfjEgTq1jXHSkc73tzDPy4?t=1713376084792'
                target='_blank'
                rel='noReferrer'
                className='block w-full rounded  px-12 py-3 text-sm font-medium text-white focus:outline-none focus:ring  border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff] sm:w-auto'>
                Chart
              </a>
            </div>
          </div>
          {/* <div className=''>
            <img src='/hero.jpg' className='w-4/6 rounded-lg m-auto' alt='' />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
