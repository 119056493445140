/** @format */

import React from "react";

const Cta = () => {
  return (
    <div className='= bg-[url(../public/7.png)] bg-cover bg-center bg-no-repeat  relative  '>
      <div className='bg-black/70'>
        <div className='max-w-5xl  text-left text-white  px-6 py-20'>
          <div>
            <h2 className='lg:font-[700] font-[800] lg:text-7xl text-4xl rotate-[-10deg] mb-4 font-sans  relative'>
              Swim2049
            </h2>
          </div>
          <div className='mt-20 flex flex-col gap-4 text-center max-w-md rotate-[-10deg]'>
            <a
              href='https://t.me/Swim_2049'
              target='_blank'
              rel='noReferrer'
              className='block w-full rounded-full  font-title font-bold  px-8 py-3  text-3xl  text-white shadow  focus:outline-none focus:ring  sm:w-auto  text-white border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]'>
              Telegram
            </a>
            <a
              href='https://twitter.com/Swim_2049'
              target='_blank'
              rel='noReferrer'
              className='block w-full rounded-full  font-title font-bold  px-8 py-3  text-3xl   text-white focus:outline-none focus:ring  border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff] sm:w-auto'>
              Twitter
            </a>
            <a
              href='https://www.dextools.io/app/en/solana/pair-explorer/FzGYbBXrCkKqyhU8XKsAStHfjEgTq1jXHSkc73tzDPy4?t=1713376084792'
              target='_blank'
              rel='noReferrer'
              className='block w-full rounded-full  font-title font-bold  px-8 py-3  text-3xl   text-white focus:outline-none focus:ring  border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff] sm:w-auto'>
              Dextools
            </a>
            <a
              href='https://solscan.io/token/rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1'
              target='_blank'
              rel='noReferrer'
              className='block w-full rounded-full font-title font-bold  px-8 py-3  text-3xl  text-white focus:outline-none focus:ring  border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff] sm:w-auto'>
              Contract
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
