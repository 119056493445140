/** @format */

import React from "react";

const Header = () => {
  return (
    <header className='flex shadow-lg py-4 px-4 sm:px-10 bg-white font-[sans-serif] min-h-[70px] tracking-wide relative z-50'>
      <div className='flex flex-wrap items-center justify-between gap-4 w-full'>
        <a
          href='/'
          className='lg:absolute max-lg:left-10 lg:top-2/4 lg:left-2/4 lg:-translate-x-1/2 lg:-translate-y-1/2'>
          <img src='/logo.png' alt='logo' className='w-36' />
        </a>
        <div
          id='collapseMenu'
          className='max-lg:hidden lg:!block max-lg:w-full max-lg:fixed max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50'>
          <button
            id='toggleClose'
            className='lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 fill-black'
              viewBox='0 0 320.591 320.591'>
              <path
                d='M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z'
                data-original='#000000'
              />
              <path
                d='M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z'
                data-original='#000000'
              />
            </svg>
          </button>
          <ul className='lg:flex lg:gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50'>
            <li className='mb-6 hidden max-lg:block '>
              <a href='/'>
                <img src='/logo.png' alt='logo' className='w-36' />
              </a>
            </li>
            <li className='max-lg:border-b max-lg:py-3 px-3'>
              <a
                href='/'
                className='hover:text-[#007bff] text-[#007bff] block font-semibold text-[15px]'>
                Home
              </a>
            </li>
            <li className='max-lg:border-b max-lg:py-3 px-3'>
              <a
                href='https://solscan.io/token/rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1'
                target='_blank'
                rel='noReferrer'
                className='hover:text-[#007bff] text-[#333] block font-semibold text-[15px]'>
                Contract
              </a>
            </li>
            <li className='max-lg:border-b max-lg:py-3 px-3'>
              <a
                href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=rX3wHE&outputCurrency=rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1&fixed=out'
                target='_blank'
                rel='noReferrer'
                className='hover:text-[#007bff] text-[#333] block font-semibold text-[15px]'>
                Raydium
              </a>
            </li>
            <li className='max-lg:border-b max-lg:py-3 px-3'>
              <a
                href='https://www.dextools.io/app/en/solana/pair-explorer/FzGYbBXrCkKqyhU8XKsAStHfjEgTq1jXHSkc73tzDPy4?t=1713376084792'
                target='_blank'
                rel='noReferrer'
                className='hover:text-[#007bff] text-[#333] block font-semibold text-[15px]'>
                Chart
              </a>
            </li>
          </ul>
        </div>
        <div className='flex items-center ml-auto space-x-6'>
          <a
            href='https://twitter.com/Swim_2049'
            target='_blank'
            rel='noReferrer'
            className='text-xl hover:text-gray-400'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='currentColor'
              className='bi bi-twitter-x fill-gray-300 inline hover:fill-[#007bff] w-6 h-6'
              viewBox='0 0 16 16'>
              <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z' />
            </svg>
          </a>

          <a
            href='https://t.me/Swim_2049'
            target='_blank'
            rel='noReferrer'
            className='text-xl hover:text-gray-400'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='currentColor'
              className='bi bi-telegram fill-gray-300 inline hover:fill-[#007bff] w-6 h-6'
              viewBox='0 0 16 16'>
              <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09' />
            </svg>
          </a>
          <a href='https://raydium.io/swap/?inputCurrency=sol&inputSymbol=rX3wHE&outputCurrency=rX3wHEid57ydcm9mrhjvTpyafTTxpNVWNowVsK2Mqj1&fixed=out' target='_blank' rel='noReferrer'>
            <button className='px-4 py-2 text-sm rounded-lg font-bold text-white border-2 border-[#007bff] bg-[#007bff] transition-all ease-in-out duration-300 hover:bg-transparent hover:text-[#007bff]'>
              Buy Swim2049
            </button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
